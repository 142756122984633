<template>
  <div>
    <div
      v-show="error"
      class="alert alert-warning"
      role="alert"
    >
      {{ error }}
    </div>
    <form
      class="join-w-name form-horizontal"
      @submit="joinMeeting"
    >
      <input
        ref="joinName"
        v-model="computedName"
        type="text"
        class="form-control"
        placeholder="Enter Your Name"
        required
      >
      <transition name="fade">
        <input
          v-if="showPinInput"
          ref="pin"
          v-model="pin"
          type="text"
          class="form-control"
          :class="['pin', {'alert': pinError}]"
          required
          title="Enter the room's PIN"
          placeholder="PIN"
        >
      </transition>

      <button
        :disabled="isJoinDisabled"
        class="btn btn-primary"
        type="submit"
      >
        Join
      </button>
    </form>

    <BeforeTesting
      v-if="isMeetingRoomLoaded"
      ref="BeforeTesting"
      :initial-call-type="callType"
      :previous-route="previousRoute"
      :is-connecting="isConnecting"
      :retry-connecting="retryConnecting"
      @has-camera="hasCameraF"
      @testing-complete="onTestingComplete"
    />
    <authorisation-prompt
      v-if="isMeetingRoomLoaded"
      :initial-call-type="callType"
      :previous-route="previousRoute"
      :is-connecting="isConnecting"
      :retry-connecting="retryConnecting"
      :has-camera="hasCamera"
      @confirm-no-camera="confirmNoCameraF"
      @testing-complete="onTestingComplete"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';

import BeforeTesting from '@/components/call/BeforeTesting.vue';
import AuthorisationPrompt from '@/components/call/AuthorisationPrompt.vue';

import ResponsiveMixin from '@/mixins/ResponsiveMixin';

import {authStore} from '@/store/__STORE_auth';
import {callStore} from '@/store/__STORE_call';
import {userStore} from '@/store/__STORE_user';
import {brandingStore} from '@/store/__STORE_branding';
import {pexipStore} from '@/store/__STORE_pexip';

export default {
  components: {
    AuthorisationPrompt,
    BeforeTesting,
  },
  mixins: [
    ResponsiveMixin,
  ],
  props: {
    webrtcAlias: {
      type: String,
      default: null,
    },
    preloadedMeetingRoom: {
      type: Object,
      default: () => {
      },
    },
    preloadedDisplayName: {
      type: String,
      default: null,
    },
    preloadedPin: {
      type: String,
      default: null,
    },
    preloadedCallType: {
      type: String,
      default: null,
    },
    retryConnecting: {
      type: Boolean,
      default: false,
    }, // Set this to true for instant meeting rooms and false for others
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMeetingRoomLoaded: false,
      callType: this.preloadedCallType,
      isConnecting: false,
      displayName: this.preloadedDisplayName,
      displayPin: this.preloadedPin,

      hasCamera: false,
      confirmNoCamera: false,

      previousRoute: {
        // name: 'welcome-guest',
        params: {
          webrtcAlias: this.webrtcAlias,
        },
      },

      meetingRoom: '',
      isAlwaysLocked: '',
      pin: this.displayPin,
      previousPin: '',

      hasChangedName: false,
    };
  },
  computed: {

    isRoomOwner: () => pexipStore.__GETTERisRoomOwner,

    is_loggedIn: (state) => authStore.__GETTERisUserLoggedIn,
    is_locked: (state) => callStore.isLocked,

    showPinInput() {
      return !this.isRoomOwner && this.meetingRoom && this.meetingRoom.guest_pin_required;
    },
    isJoinDisabled() {
      return !this.displayName || (this.meetingRoom && this.meetingRoom.guest_pin_required && !this.pin) || this.displayName.length > 30; // There is no good way of detecting that a pin is required
    },
    pinError() {
      return this.error && this.error.indexOf('PIN') !== -1;
    },
    computedName: {
      get: function() {
        if (this.is_loggedIn && !this.hasChangedName) {
          if (userStore.__GETTERfullName.length <= 30) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.displayName = userStore.__GETTERfullName;
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.displayName = userStore.__GETTERfirstName;
          }

          return this.displayName;
        }
        return this.displayName;
      },
      set: function(value) {
        this.hasChangedName = true;
        this.displayName = value;
      },
    },
      pin: {
        get: function() {
          if (this.is_loggedIn) {
            return this.displayPin;
          }
          return this.displayPin;
        },
        set: function(value) {
          this.displayPin = value;
        },
      },
  },
  watch: {
    is_loggedIn() {
      this.isLoggedIn = this.is_loggedIn;
    },
    is_locked() {
      this.isLocked = this.is_locked;
    },
    preloadedCallType() {
      this.callType = this.preloadedCallType;
      // this.resetMeetingRoom();
    },
    showPinInput() {
      this.focusIfEmpty();
    },
    displayName(newVal, oldVal) {
      if (this.displayName.length > 30 && newVal.length > oldVal.length) {
        this.$toast.error('Your name cannot be longer than 30 characters');
      }
    },
  },
  mounted() {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (iOS) {
      const ua = window.navigator.userAgent;
      const webkit = !!ua.match(/WebKit/i);
      const isSafari = webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);
      if (!isSafari) {
        const error = 'On Apple iOS, only Safari browser is supported.';
        this.redirectBack(error);
        return;
      }
    }

    if (!this.webrtcAlias) {
      const routeName = this.isLoggedIn ? 'dashboard' : 'join-meeting';
      this.$router.push({name: routeName});
    }

    this.loadMeetingRoom();
    this.focusIfEmpty();
  },
  created() {
    window.addEventListener('beforeunload', this.closeBrowser);
  },
  beforeDestroy() {
    this.$emit('set-room-brand', null);
    window.addEventListener('beforeunload', this.closeBrowser);
  },
  methods: {
    loadMeetingRoom() {
      if (this.meetingRoom) {
        this.onMeetingRoomLoaded();
        return;
      }

      const url = '/join-meeting-room/' + this.webrtcAlias;
      axios({url: url, method: 'GET'})
          .then((resp) => {
            this.meetingRoom = resp.data;
            this.onMeetingRoomLoaded();
          })
          .catch((err) => {
            this.redirectBack();
          });
    },

    onMeetingRoomLoaded() {
      callStore.setLock(this.meetingRoom.is_locked);
      this.isAlwaysLocked = this.meetingRoom.is_locked;
      this.pin = this.meetingRoom.webrtc.pin;
      this.previousPin = this.meetingRoom.webrtc.previous_pin;

      if (this.pin) {
        // PIN is only returned from backend for authorised users. So this is host.

        pexipStore.setIsRoomOwner(true);

        if (!this.displayName) {
          this.displayName =
          this.meetingRoom.host.name + ' ' + this.meetingRoom.host.last_name;
        }

        this.previousRoute = {
          name: 'host',
          params: {webrtcAlias: this.webrtcAlias},
        };
      } else {
        this.pin = this.preloadedPin;
      }

      this.isMeetingRoomLoaded = true;

      this.$emit('set-room-brand', this.meetingRoom.brand);
      brandingStore.setLogo( {
        logo: this.meetingRoom.brand.logo,
        level: this.meetingRoom.brand.level,
      });
      brandingStore.setGuestWelcomeMessage(
          this.meetingRoom.brand.guest_welcome_message,
      );
    },

    resetMeetingRoom() {
      this.meetingRoom = null;
      this.loadMeetingRoom();
    },

    onTestingComplete(data) {
      const params = {
        testingCompleted: true,
        settingsData: data,
        webrtcAlias: this.webrtcAlias,
        preloadedMeetingRoom: this.meetingRoom,
        preloadedDisplayName: this.displayName,
        preloadedCallType: this.callType,
        preloadedPin: this.pin,
        isRoomOwner: this.isRoomOwner,
        hasNoCamera: !this.hasCamera,
      };


      this.$router.push({
        name: 'ongoing-meeting',
        params: params,
      });
    },

    redirectBack(error) {
      const params = Vue.util.extend({}, this.previousRoute.params);
      params.error = error;

      this.$router.push({
        name: this.previousRoute.name,
        params: params,
      });
    },

    toggleMyAudio() {
      const shouldMute = this.actions.microphone.enabled && !this.actions.microphone.mutedRemotely;

      // Toggle all microphone states to the opposite (if mic active, then mute)
      this.actions.microphone.enabled = !shouldMute;
      this.actions.microphone.mutedRemotely = shouldMute;
      this.pexip.setParticipantMute(this.myUuid, shouldMute);
      this.pexip.muteAudio(shouldMute);

      this.isShowSettings = false;
    },

    toggleMyVideo() {
      const value = this.actions.camera.enabled;

      this.pexip.muteVideo(value);
      this.actions.camera.enabled = !value;

      this.isShowSettings = false;
    },

    /**
     * Toggle the visiblity of the settings panel on the page
     */
    toggleSettings() {
      if (!this.isMobileDevice()) {
        this.isShowSettings = !this.isShowSettings;
      } else {
        if (!this.isShowSettings) {
          this.isShowSettings = true;
        }
      }
    },

    joinMeeting() {
      if (this.displayName == '') {
        this.$toast.error('You must provide a name!');
        this.$refs.joinName.focus();
        return;
      }
      if (this.displayName.length > 30) {
        this.$toast.error('Your name cannot be longer than 30 characters');
        this.$refs.joinName.focus();
        return;
      }
      localStorage.setItem('guestDisplayNameTwo', this.displayName);
      this.$refs.BeforeTesting.onTestingComplete();
    },

    hasCameraF(bool) {
      this.hasCamera = bool;
    },
    confirmNoCameraF(bool) {
      this.confirmNoCamera = bool;
    },
    focusIfEmpty() {
      if (!this.displayName) {
        this.$refs.joinName.focus();
      } else if (this.showPinInput && !this.pin) {
        window.setTimeout(() => {
          this.$refs.pin.focus();
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  form.join-w-name {
    display: flex;
    justify-content: flex-start;

    button,
    input[type=text] {
      border-radius: 5px;
      font-size: 18px;
      min-height: 50px;
      padding: 11px 20px;
    }

    input[type=text] {
      border: 1px solid #ddd;
      height: unset;
      margin-right: 20px;
      width: 55%;

      &.alert {
        border-color: red;
        margin-bottom: 0;
      }
    }

    input.pin {
      width: 20%;
    }
  }

  @include media-breakpoint-down(sm) {
    form.join-w-name {

      button,
      input[type=text] {
        border-radius: 5px;
        flex: 0 1 10%;
        font-size: 18px;
        min-height: 50px;
        padding: 11px 20px;
      }

      input[type=text] {
        border: 1px solid #ddd;
        flex: 1 0 auto;
        margin-right: 20px;
      }

      input.pin {
        width: 20%;
      }
    }
  }

  // Transitions
  .fade-enter-active {
    transition: all .35s;
  }
</style>
